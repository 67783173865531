.searchFieldBox {
  display: flex;
  place-content: center;
}
.flex {
  display: flex;
}
.center {
  align-self: center;
}
.webkitCenter {
  -webkit-box-pack: center;
}
.borderRadius {
  border-radius: 26px;
}
.icon {
  align-self: center;
}
.button {
  color: #072d26;
  font-weight: bold;
  background: #d9e868;
  border-radius: 26px;
}
.backgroundImageHeader > div > div > div h3 {
  margin-bottom: 40px;
  /* max-height: 800px; */
}
.backgroundImageHeaderContainer > img {
  max-height: 800px;
  min-height: 800px;
}
.mapHeader {
  margin-bottom: 38px;
}
.mapIconsContainer > div {
  display: flex;
}
.mapIconsContainer  h4 {
  white-space: pre-line;  
}
.mapIconsContainer > div > div {
  align-self: center;
  margin-left: 5%;
}
.mapButton{
  width: 340px;
  height: 51px;
  margin-top: 53px;
}
.accordion {
  border-radius: 16px;
  margin-bottom: 8px;
}
.accordion >svg >path{
  fill: #005851!important;
  color: #005851;

}
.accordionDetails {
  background: #f6f3ee;
  font-weight: bold;
}
.textCenter {
  align-self: center;
}
.positionSearchInput > div > fieldset {
  border-style: none;
}
.textField {
  background-color: #ffffff;
  border-radius: 26px;
  max-height: 51px;
  max-width: 300px;
}
.positionSearchHeader {
  margin-bottom: 20px;
}
.positionSearchForm {
  flex-wrap: wrap;
  gap: 25px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  color: #072d26;
  display: flex !important;
}
.positionSearchSelect::before {
  border-bottom: hidden;
}
.positionSearchInputParent {
  display: flex;
  flex-grow: 1;
}
.positionSearchInput {
  /* it was 150px */
  min-width: 150px; 
  flex-grow: 1;
  border-style: none;
}
.positionSearchSelect {
  padding-left: 5px;
}
.positionSearchSelect:hover {
  /* outline: 1px solid rgb(70, 70, 70);
     */
  border-radius: 5px;
}
.positionSearchButton {
  height: 56px;
  width: 127px;
  flex-grow: 1;
}
.positionSearchButton:hover {
  background-color: #C0D904
}
.positionSearchContainer {
  width: 100%;
  background-color: #d0c2a8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accordionColorContainer {
  width: 100%;
  background-color: #F6F3EE;
}

.positionSearchInnerContainer {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accordionContainer {
  width: 70%;
}
.leftSidedContainer {
  width: 70%;
}
.accordionContainer > div > div > div > h4 {
  white-space: pre-line;
}
.accordionBottomText {
  margin-top: 1.56rem;
  margin-bottom: 8%;
  font-weight: bold;
}
.contactFormInner {
  padding: 56px;
}
.contactFormInner p {
  margin-bottom: 8px;
}
/* .contactFormInner div div {
  margin-bottom: 16px;
} */


.contactFormButtonBlock > div > div > input::placeholder {
  transform: translateY(-100px);
}
.contactFormHeader {
  margin-bottom: 24px;
}
/* .contactFormHeader input:hover {
  background-color: red;
  border: 1px soldi red;
} */
/* .contactFormMessage > input {
  min-width: 371px;
  min-height: 223px;
} */
.contactFormButtonBlock > div > div > input {
  min-height: 223px;
}
.contactFormButton {
  margin-top: 16px;
  min-height: 51px;
  max-width: 250px;
}


.blockContainer {
  padding-top: 120px;
  padding-bottom: 60px;
}
.contactFormOuter >div >div {
 
  top: 60px;
  
}
@media (max-height: 890px) {
  .contactFormOuter >div >div {
    top: 0px; 
  }

}
@media (max-width: 1200px) {
  .contactFormOuter >div >img {
    max-height: 400px;
  }
}


.contactFormInputMediaQuery  {
  margin-bottom: 10px;
  flex-grow: 1;
}
.mapIconsSubmit {
  height: 51px;
  margin-top: 53px;
  padding-left: 32px;
  padding-right: 32px;
}


@media (max-width: 900px) {
  .contactFormButton {
    max-width: inherit;
  }
}

@media (max-width: 1200px) {
  .contactFormInner {
    padding: 32px;

  }

  .accordionContainer {
    width: 85%;  
  }
  .leftSidedContainer {
    width: 100%;  
  }

  .backgroundImageHeader {
    padding-left: 0px;
    padding-right: 0px;
  }

  .backgroundImageHeader >div >div >div:nth-child(2) {
   /* visibility: hidden; */
   /* display: none; */
  }
  .smartphoneContainer {
    padding: 32px;
  }
  
  .blockContainer {
    padding: 32px
  }
}

@media (max-width: 1000px) {  
  .mapGrid {
    display: flex;
    flex-direction: column;
    /* display: flex;
         */
  }
  .mapIconsSubmit {
    flex-grow: 1
  }
  .accordionContainer {
    width: 100%;  
  }
}
@media (max-width: 1200px) {  
  .accordionContainer >div h4 {
    font-weight: 700;
  }

}
@media (min-width: 1200px) {  
  .positionSearchInput {
    min-width: 320px; 
  }


}
@media (max-width: 1200px) {  
  .mapIconsContainer >div h4 {
    font-weight: 700;
  }
  .contactFormInner {
    padding: 32px;
    margin-left: -16px;
  }

}
@media (max-width: 1200px) {  
  .mapIconsContainer svg {
    transform: scale(.75);
    
  }
  .backgroundImageHeader   {
    background: #072D26
  }

}

@media (min-width: 600px){
  .backgroundImageHeader  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
/* @media (min-width: 300px){
  .backgroundImageHeader  {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
} */