.register{
  display: flex;
}
@media(max-width: 1040px){
  .register{
    flex-direction: column;
  }
}

.inputs{
  display: none;
  padding: 2px 4px;
  align-items: center;
  border-radius: 30px !important;
  margin: auto 16px;
}
.button{
  border-radius: 50%;
}

.extendableShow{
  display: flex;
  padding: 2px 4px;
  align-items: center;
  border-radius: 30px !important;
  margin: auto 16px;
}
.lossucheContainer{
  border-radius: 30px !important;
  display: flex;
  /* display: grid;
  grid-template-columns: 2fr 3fr; */
} 
.lossucheImage{
  border-radius: 30px 0px 0px 30px;
  flex: 1 1 0px;
  display:flex;
} 
/* @media(max-width: 600px){
  .lossucheImage{
    border-radius: 30px 0px 0px 30px;
    width: 200px;
  } 
} */

.lossucheInfo{
  float: left;
  padding: 15px 0px 15px;
  margin-right: 25%;
}

.lossucheInfoIcon{
  font-size: 15px;
  padding: 1px;
}

.MuiSlider-valueLabel::before{
  font-size: .55rem !important;
}


.scrollStyle::-webkit-scrollbar {
  width: 14px;
}

/* Track */
.scrollStyle::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.scrollStyle::-webkit-scrollbar-thumb {
  background: #072C25cc; 
}

/* Handle on hover */
.scrollStyle::-webkit-scrollbar-thumb:hover {
  background: #072C25; 
}

.display{
  /* display: none; */
  /* opacity: 0;
  margin-left: -9999; */
 
}