.coloredBoxBanner {
  padding: 50px;
}

@media (max-width: 550px) {
  .coloredBoxBanner {
    padding: 0px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

@media (min-width: 550px) and (max-width: 1200px) {
  .coloredBoxBanner {
    padding-left: 80px;
    padding-top: 50px;
    padding-bottom: 20px;
  }
}