.backgroundImage {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.backgroundImage--desktop {
    height: 80vh;
    min-height: 600px;
    
}

.backgroundImage__image {
    flex-basis: auto;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.backgroundImage__children {
    flex-grow: 1;
}

.backgroundImage--desktop .backgroundImage__children {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
}

.backgroundImage--desktop .backgroundImage__children_customPos {
    width: 100%;
    /* display: flex; */
    justify-content: center;
    position: absolute;
}

.reverseChildren {
    flex-direction: column-reverse;
}