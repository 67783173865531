.imageLink{
  color: #000000;
  font-weight: bold;
 
}

.imageIcons{
  display: flex;
  
  justify-content: flex-end;
 
}
.imageIcons svg{
  fill: #005851 ;
 
}

.imagePreviewContainer input{
  display: none;
}
.imagePreviewContainer svg{
  width: 20px;
  height: 20px;
}

.insideIconsRight{
  display: flex;
  /* margin-left: 90%; */
}


.imagePreviewLabel {
  font-size: 16px;
  font-weight: bold;
  white-space: pre;
  
}
.imageIcons {
  margin-bottom: 24px;

}
.multiUpload{
  display: none;
}
