@import-normalize;
@font-face {
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans-Regular.ttf") format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans-Italic.ttf") format('truetype');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans-BoldItalic.ttf") format('truetype');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans-Medium.ttf") format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'DMSans';
  src: url("./assets/fonts/DMSans-MediumItalic.ttf") format('truetype');
  font-style: italic;
  font-weight: 500;
}

body {
  font-family: 'DMSans';
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

/* remove autofill color in chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}