.login{
  display: flex;
}
.input {
  margin: 5px !important;
}

.form > div > div{
  border-radius: 30px;
}

.info{
  font-size: 15px;
  align-self: flex-start;
  margin-left: 5px;
}



@media(max-width: 1040px){
  .login{
    flex-direction: column;
  }
  .register{
    padding: 0px;
    margin-top: 40px;
  }
}

.icon{
  font-size: small;
  height: 14px;
}

