.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.outlet {
  flex-grow: 1;
}


@media (max-width: 1200px) {
  .leaflet {
    width: 100%;
    height: 80vh;
  }
}

@media (min-width: 1200px) {
  .leaflet {
    width: calc(100% - 650px);
    /* width: 70%; */
    height: 100vh;
  }
}
