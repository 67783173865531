.hoverLine {
  position: relative;
  color: #D9E868;
  text-decoration: none;
}

.hoverLine:hover {
  color: #D9E868;
}
.hoverLine:hover p {
  color: #D9E868;
}
.hoverLine::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #D9E868;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.2s ease;
  text-decoration: none;
}

.hoverLine:hover::before {
  transform: scaleX(1);
  text-decoration: none;
}
