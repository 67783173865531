.mainContainer{
  /* margin-left: 17%; */
  /* margin-right: 17%; */
  /* width: 85%; */

}
.roundInputs{
  border-radius: 26px ;
}
.sizeInputs{
  max-width: 300px ;
  height: 51px;
  padding: 0px 42px;
}
.buttonColor{
  background: #D9E868;
}
.boldFont{
  font-weight: bold;
}
.fontSize{
  font-size: 24px;
}
.buttonColor:hover{
  background: #C0D904;
}
.mainContainer{
  margin-top: 4.9%;
  margin-bottom: 3.4%;
} 
.editHeader{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.editHeader h3{
  overflow: inherit;
}

.LotOfferLocations h5{
  margin-bottom: 16px;
}
.LotOfferOptions h5{
  margin-bottom: 16px;
}
.LotOfferImages h5{
  margin-bottom: 16px;
}

.LotOfferMasterData {

}
.LotOfferContainer >div p:nth-child(2){
  opacity: 0.6;
}


.LotOfferOptions div {
  /* padding: 30px; */
  /* margin: 15px 0; */


}

.LotOfferImages >div p{
  margin-bottom: 16px;


}
.LotOfferImages >div >div img{
  
 background: url(http://goo.gl/vyAs27) no-repeat scroll 0 0;

}

.backSVG{
  width: 9.5px;
  height: 9.5px;
  margin-bottom: 29px;
  margin-right: 8px;

}
.backContainer{

  display: flex;
}

