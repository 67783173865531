.register{
  display: flex;
}
.input {
  margin: 5px !important;
}
.error{
  font-size: 0.7rem;
  font-weight: 500;
  color: red;
  margin-top: 0.2rem;
  text-align: start;
}

.form > div > div{
  border-radius: 30px;
}
.info{
  font-size: 15px;
  align-self: flex-start;
  margin-left: 5px;
  text-align: start;
}
.login{
  padding: 76px;
}
@media(max-width: 1040px){
  .register{
    flex-direction: column;
  }
  .login{
    padding: 0px;
    margin-top: 40px;
  }
}

.inputStyle{
  margin: 5px !important;
  border-color: #D0C2A8 !important;
  accent-color: #D0C2A8;
  
  
  
}
.inputStyle:click{
  color: #D0C2A8 !important;
  border-color: #D0C2A8 !important;
  

}
