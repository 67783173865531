.tableHeader{
  font-weight: 600 !important;
}
.activeTab{
  background-color: #FFFFFF;
  color: hsl(169, 73%, 10%) !important;
  border: 1px solid #D0C2A8 !important;
  border-bottom: none !important;
  margin-right:5px !important;
}
.inactiveTab{
  background-color: #F6F3EE !important;
  border: none !important;
  color: #000000 !important;
  margin-right: 5px !important;
}
.inputs{
  padding: 2px 4px;
  align-items: center;
  border-radius: 30px !important;
  width: 35%;
  height: 40px;
}