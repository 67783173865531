.carouselcontainer {
  width: 100%;
  height: 100%;
  overflow: hidden;

}


.carouselcontent {
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carouselcontent::-webkit-scrollbar, .carouselcontent::-webkit-scrollbar {
  display: none;
}

.carouselcontent > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}


/* ... */
.leftarrow, .rightarrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
  cursor:pointer
}


/* ... */

.leftarrow {
  left: 2%;
  top: 50%;
  position: fixed;
}

.rightarrow {
  right: 2%;
  top: 50%;
  position: fixed;
}

.mediaContainer {
  width: 100vw ;
  height: 100vh;
}


.closeItem {
  position: absolute;
  right: 20px;
  top: 80px;
  width: 44px;
  opacity: 0.8;
  fill: white;
}
.closeItem:hover {
  opacity: 1;
}

@media (max-width:800px) or (max-height: 600px) {
  .leftarrow {
    left: 20px;
    top: 50%;
    position: fixed;
  }
  
  .rightarrow {
    right: 20px;
    top: 50%;
    position: fixed;
  }
}

@media (min-width:800px) and (min-height: 550px) {
  .closeItem {
    top: 20px;
  }
  .mediaContainer {
    width: 750px;
    height: auto;
    aspect-ratio: 4/3;
  }
}
@media (min-width:1200px) and (min-height: 900px) {
  .mediaContainer {
    width: 1000px;
    height: auto;
    aspect-ratio: 4/3;
  }
}

@media only screen and (max-width: 520px) {
  .losDetailContainer >div div:nth-child(2) h3 {
    font-size: 14px;
  }
}
@media only screen and (max-width: 320px) {
  .losDetailContainer >div div:nth-child(2) h3 {
    font-size: 10px;
  }
}




      

