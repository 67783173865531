.form{
  display: flex;
}
@media(max-width: 1040px){
  .form{
    flex-direction: column;

  }
}

.input{
  border-radius: 26px !important;
}

.form div p {
  margin-top: 8px;
}
/* .form:last-child p {
  margin-top: 0px;
} */

.notDetect{
  color: inherit;
}