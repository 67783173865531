@media only screen and (max-width: 460px) {
  .shoppingCartListItem div div div button p {
    display: none;
  }
  .shoppingCartListItem div div div {
 
    padding-left: 0;
    padding-right: 0;
  }
  .shoppingCartListItem div div {
    padding-left: 0;
    padding-right: 0;
  }
  .shoppingCartListItem div div {
    gap: 10px;
  
  }

}